import React from 'react';
import { withStyles,Box,Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = theme => ({
  root: {}
});

//Js
class TabPanel extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div
          role="tabpanel"
          hidden={this.props.value !== this.props.index}
          id={`simple-tabpanel-${this.props.index}`}
          aria-labelledby={`simple-tab-${this.props.index}`}
          {...this.props.other}
        >
          {this.props.value === this.props.index && (
            <Box p={3}>
              <Typography>{this.props.children}</Typography>
            </Box>
          )}
        </div>
      </div>
    );
  }
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
//  var mapStateToProps = state => {
//     return {
//       selectedMenu: state.selectedMenu
//     };
//   };

//export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
export default withStyles(useStyles)(TabPanel);
