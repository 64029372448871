import React from 'react'
import { withStyles, Box, InputBase } from '@material-ui/core';

//css
const useStyles = (theme) => ({
    root:{
backgroundColor:'#e5e5e5',
padding:'7px',
borderRadius: '6px'
    },
    classname2:{

    }
})
//Js
class Message extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        const {classes} = this.props

        return (<div className={classes.root}>{this.props.message}</div>) 
    }
}

export default withStyles(useStyles)(Message)
