import React from 'react';
import { withStyles, Box } from '@material-ui/core';
import { Height } from '@material-ui/icons';
import VideoButtons from './VideoButtons';
import CancelIcon from '@material-ui/icons/Cancel';
import LocalVideo from './LocalVideo';
import RemoteVideo from './RemoteVideo';
const io = require('socket.io-client');

//css
const useStyles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'blue'
  },
  remotevideo: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 1
  },
  localvideo: {
    width: '100',
    height: '100',
    position: 'absolute',
    bottom: '0',
    right: '0',
    zIndex: 3
  },
  videobuttons: {
    width: '100%',
    height: '50px',
    position: 'absolute',
    bottom: '5%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center'
  }
});
// variables
var roomNumber = 'webrtc-audio-demo';
var localStream;
var remoteStream;
var rtcPeerConnection;
var iceServers = {
  iceServers: [
    {
      url: 'stun:stun.services.mozilla.com'
    },
    {
      url: 'stun:stun.l.google.com:19302'
    }
  ]
};
var streamConstraints;
var isCaller;

// Let's do this
var socket = io("https://198.12.225.108:3001/");

socket.on('candidate', function(event) {
  var candidate = new RTCIceCandidate({
    sdpMLineIndex: event.label,
    candidate: event.candidate
  });
  console.log(new Date().toLocaleTimeString() + ' = candidate');
  rtcPeerConnection.addIceCandidate(candidate);
});

socket.on('answer', function(event) {
  rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(event));
  console.log(new Date().toLocaleTimeString() + ' = answer');
});

// handler functions
function onIceCandidate(event) {
  if (event.candidate) {
    console.log('sending ice candidate');
    socket.emit('candidate', {
      type: 'candidate',
      label: event.candidate.sdpMLineIndex,
      id: event.candidate.sdpMid,
      candidate: event.candidate.candidate,
      room: roomNumber
    });
    console.log(new Date().toLocaleTimeString() + ' = onIceCandidate');
  }
}

function setLocalAndOffer(sessionDescription) {
  rtcPeerConnection.setLocalDescription(sessionDescription);
  socket.emit('offer', {
    type: 'offer',
    sdp: sessionDescription,
    room: roomNumber
  });
  console.log(new Date().toLocaleTimeString() + ' = setLocalAndOffer');
}

function setLocalAndAnswer(sessionDescription) {
  rtcPeerConnection.setLocalDescription(sessionDescription);
  socket.emit('answer', {
    type: 'answer',
    sdp: sessionDescription,
    room: roomNumber
  });
  console.log(new Date().toLocaleTimeString() + ' = setLocalAndAnswer');
}

function toggleAudio(state) {
  localStream.getAudioTracks()[0].enabled = state;
//   socket.emit('toggleAudio', {
//     type: 'toggleAudio',
//     room: roomNumber,
//     message: localStream.getAudioTracks()[0].enabled
//       ? "Remote user's audio is unmuted"
//       : "Remote user's audio is muted"
//   });
}
function toggleVideo(state) {
    localStream.getVideoTracks()[0].enabled = state; 
}
//Js
//GET_STREAM

class VideoCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = { state: 'IDLE', lvstatus: false, isCaller: false,height:1250,width:580 };
    this.startOrJoinCall();
    this.on = props.onStreamConnected;
  }

  startOrJoinCall = () => {
    socket.emit('create or join', roomNumber);
    this.socketCallback();
  };

  socketCallback = () => {
    socket.on('created', (room)=> {
      this.setState({ lvstatus: true, isCaller: true });
    });

    socket.on('joined', (room) =>{
      this.setState({ lvstatus: true});
      socket.emit('ready', roomNumber);
    });

    socket.on('ready', () =>{
      if (this.state.isCaller) {
        this.createPeerConnection();
        let offerOptions = {
          offerToReceiveAudio: 1
        };
        console.log(new Date().toLocaleTimeString() + ' = ready');
        rtcPeerConnection
          .createOffer(offerOptions)
          .then(desc => setLocalAndOffer(desc))
          .catch(e => console.log(e));
      }
    });
    socket.on('offer', (event)=> {
      if (!this.state.isCaller) {
        this.createPeerConnection();
        rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(event)
        );
        rtcPeerConnection
          .createAnswer()
          .then(desc => setLocalAndAnswer(desc))
          .catch(e => console.log(e));
        console.log(new Date().toLocaleTimeString() + ' = offer');
      }
    });
  };

  createPeerConnection = () => {
    rtcPeerConnection = new RTCPeerConnection(iceServers);
    rtcPeerConnection.onicecandidate = onIceCandidate;
    rtcPeerConnection.onaddstream = this.onAddStream;
    rtcPeerConnection.addStream(localStream);
    console.log(new Date().toLocaleTimeString() + ' = createPeerConnection');
  };

  onAddStream =(event)=> {
    remoteStream = event.stream;
    this.setState({ state: 'GET_STREAM' });
    this.props.onStreamConnected();
  };

  setLocalStream=(stream)=>{
    localStream = stream
  }
  componentDidMount = () => {
    const rect =document.getElementsByClassName(this.props.classes.remotevideo)[0].getBoundingClientRect();
    const width = rect.width > rect.height ? rect.width : rect.height
    const height = rect.width > rect.height ? rect.height : rect.width
    this.setState({'height':height,'width':width});
  }
  onCloseVideo=()=>{
    this.props.onClose();
    const tracks = localStream.getTracks(); 
    tracks.forEach(function(track) {
      track.stop();
    });
  }

  render() {
     const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Box className={classes.remotevideo}>
          <RemoteVideo ref="remotevideo" stream={remoteStream}></RemoteVideo>
        </Box>
        <Box className={classes.localvideo}>
          <LocalVideo width={this.state.width}  height={this.state.height} status={this.state.lvstatus} returnStream={this.setLocalStream}></LocalVideo>
        </Box>
        <Box className={classes.videobuttons}>
          <VideoButtons closeVideo={this.onCloseVideo} toggleAudio ={toggleAudio} audio={this.props.audio} video={this.props.video} toggleVideo ={toggleVideo}></VideoButtons>
        </Box>
      </div>
    );
  }
}

var mapStateToProps = state => {
  return {
    selectedMenu: state.selectedMenu
  };
};

//export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
export default withStyles(useStyles)(VideoCall);
