import { PROFILE_VIEW, CHAT_VIEW,NEWCHAT_VIEW,ACTION_MSG,CLOSE_VIDEO_CALL } from './ProfileType';
const initialstate = {
  isProfile: false
};
const ProfileReducer = (state = {}, Action) => {
  switch (Action.type) {
    case PROFILE_VIEW:
      return {
        ...state,
        selectedMenu: 'profile'
      };
      case CLOSE_VIDEO_CALL:
        return {
          ...state,
          isOpenVideoModal: false
        };
      case CHAT_VIEW:
      return {
        ...state,
        selectedMenu: 'chatlist'
      };
      case NEWCHAT_VIEW:
      return {
        ...state,
        selectedMenu: 'newchat'
      };
      case ACTION_MSG:
        return {
          ...state,
          selectedMenu: 'actionmessage'
        };
    default:
      return state;
  }
};
export default ProfileReducer;
