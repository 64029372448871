import React from 'react';
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import  TabPanel from './TabPanel'
import Search from './Search'
import UserList from 'src/views/List/Users';
const useStyles = theme => ({
  root: {}
});

//Js
class ComponentName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value:0};
  }
  a11yProps = (index) =>{
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  handleChange = (event, newValue) => {
    this.setState( {value:newValue});
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={this.props.value}
            onChange={this.handleChange}
            aria-label="Group tab"
          >
            <Tab label="Waiting List" {...this.a11yProps(0)} />
            <Tab label="Open List" {...this.a11yProps(1)} />
           </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
            <Search></Search>
            <UserList />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
             <UserList />
        </TabPanel>
      </div>
    );
  }
}

//  var mapStateToProps = state => {
//     return {
//       selectedMenu: state.selectedMenu
//     };
//   };

//export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
export default withStyles(useStyles)(ComponentName);
