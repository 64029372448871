import React from 'react';
import {
  withStyles,
  Box,
  Avatar,
  IconButton,
  Dialog,
  Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import { spacing } from '@material-ui/system';
import { green, red } from '@material-ui/core/colors';
import videsound from '../sound/videosound.mp3';
import VideoCall from '../layouts/VideoCall';
var Sound = require('react-sound').default;

//css
const useStyles = theme => ({
  root: {},
  avatarWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    position:"absolute",
    top:0,
    left:0,
    flexDirection: 'column',
    alignItems: 'center',
    height:"100%",
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
    zIndex: 1
  },
  hidden: { 
    display:'none' 
  },
  avatar: {
    cursor: 'pointer',
    width: 100,
    height: 100,
    margin: '20px',
    zIndex: 1
  }
});
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
//Js
class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {connected:false};
  }

  onClose = e => {
    this.props.onCloseVideo();
     
  };
 

  onStreamConnected =()=>{
    this.setState({connected:true});
  }
  render() {
    const { classes,clicked } = this.props;
    if(clicked == "call"){
      this.audio = true;
      this.video = false;
    }else{
      this.audio = true;
      this.video = true;
    }

    return (
      <div className={classes.root}>
        <Dialog
          onClose={this.onClose}
          maxWidth="xl"
          fullWidth="false"
          fullScreen="true"
          open={this.props.isOpenModal}
        >
          <VideoCall onClose={this.onClose} audio={this.audio} video={this.video} onStreamConnected={this.onStreamConnected}></VideoCall>
          <Box className={this.state.connected ? classes.hidden: classes.avatarWrapper}>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
              to="/app/account"
              variant="circular"
            />
            <Typography m={2} variant="h3" align="center">
              Connecting.....
            </Typography>
          </Box> 
          <Box className={classes.menuWrapper}>
            <Sound
              url={videsound}
              playStatus={Sound.status.PLAYING}
              playFromPosition={300 /* in milliseconds */}
              onLoading={this.handleSongLoading}
              onPlaying={this.handleSongPlaying}
              onFinishedPlaying={this.handleSongFinishedPlaying}
            />
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(VideoModal);
