import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List
} from '@material-ui/core';
 
import ChatListItem from 'src/components/ChatListItem';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  inline: {
    display: 'inline'
  }
}));


const ChatList = () => {
const classes = useStyles();

 
  return (
    <List className={classes.root}>
    <ChatListItem></ChatListItem>
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem>
    <ChatListItem></ChatListItem>
    <ChatListItem></ChatListItem>
    <ChatListItem></ChatListItem>

    </List>
  );
};

export default ChatList;
