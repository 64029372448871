import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    name: 'College',
    type: 'Admin'
  },
  {
    id: uuid(),
    name: 'Hospital',
    type: 'Admin'
  },
  {
    id: uuid(),
    name: 'Doctor',
    type: 'Parent'
  },
  {
    id: uuid(),
    name: 'Patient',
    type: 'Child'
  },
  {
    id: uuid(),
    name: 'Teacher',
    type: 'Parent'
  },
  {
    id: uuid(),
    name: 'Student',
    type: 'Child'
  },
];
