import React from 'react';
import {
  withStyles,
  Box,
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  InputBase
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { FullscreenExit } from '@material-ui/icons';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { connect } from 'react-redux';

import {
  enterChatMsg
} from '../Action/ProfileAction';
//css
const useStyles = theme => ({
  chatHeader: {
    width: '100%',
    height: '5rem',
    backgroundColor: 'white',
    left: '0px',
    bottom: '0px',
    display:'flex',
    alignItems:'center'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%'
  }
});

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
const ITEM_HEIGHT = 48;

const options = [
  'Document',
  'Camera',
  'Gallery',
  'Audio',
  'Location',
  'Contacts'
];

class ChatInputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null,message:"" };
  }

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };
  setAnchorEl = target => {
    this.setState({ anchorEl: target });
  };
  handleClose = () => {
    this.setAnchorEl(null);
  };

  sentMessage = () => {
    this.refs.inputtext.value ="";
    this.props.onEnterMessage(this.state.message);
  }
  onChangeText = (e)=>{
    this.setState( {message:e.target.value})
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.chatHeader}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
          <IconButton width = '100%'
                color="primary"
                aria-label="upload picture"
                component="span"
              >
          <EmojiEmotionsOutlinedIcon/>
           </IconButton>
          <InputBase ref="inputtext"
                      placeholder="write a message"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      onChange ={(e)=> this.setState( {message:e.target.value})}
                      inputProps={{ 'aria-label': 'search' }}
                    />

          </Grid>
          <Grid item xs={3} display="flex">
            <Box className={classes.menuWrapper}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <AttachmentOutlinedIcon  ></AttachmentOutlinedIcon>
              </IconButton>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={this.sentMessage}
              >
                <SendOutlinedIcon  />
              </IconButton>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch'
                  }
                }}
              >
                {options.map(option => (
                  <MenuItem
                    key={option}
                    selected={option === 'Pyxis'}
                    onClick={this.handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// var mapDispatchToProps = dispatch => {
//   return {
//     sentMessage : () => dispatch(enterChatMsg()),
//   } 
// };

// export default connect(
//   null,
//   mapDispatchToProps
// )(withStyles(useStyles)(ChatInputText));
export default withStyles(useStyles)(ChatInputText);