import React from 'react';
import { withStyles, Box, InputBase } from '@material-ui/core';
import UserMessage from './UserMessage';
import MyMessage from './MyMessage';

//css
const useStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding:'20px',
    overflow:'auto'
  }
});

//Js
class ChatBox extends React.Component {

  constructor(props){
    super(props);
    this.state = {chatArray:[]}; 
  }

  addChatMessage=(message)=>{
    this.state.chatArray.push(message)
    this.setState({
      chatArray:this.state.chatArray
   })
  }
 

  render() {
    const { classes } = this.props;

    return <div className={classes.root} >
      { this.state.chatArray.map(message => <MyMessage message={message}></MyMessage> ) 
}
    </div>;
  }
}

export default withStyles(useStyles)(ChatBox);
