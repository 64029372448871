import React from 'react'
import { withStyles} from '@material-ui/core';
//css
const useStyles = (theme) => ({
    root:{
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%', 
      overflow: 'hidden',
     } ,
    remoteVideo:{
      width:'100%'
    }
})
 
//Js
//VIDEO, AUDIO, IDLE
class RemoteVideo extends React.Component{
    constructor(props){
      super(props)
    } 

    componentDidUpdate=()=>{
      let remoteVideo = this.refs.remoteVideo;
      remoteVideo.srcObject = this.props.stream;
    }
    render(){
        const {classes} = this.props

        return (<div className={classes.root}>
          <video ref="remoteVideo" id="remoteVideo" className={classes.remoteVideo} autoPlay></video>
        </div>) 
    }
}

 var mapStateToProps = state => {
    return {
      selectedMenu: state.selectedMenu
    };
  };

  //export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
  export default withStyles(useStyles)(RemoteVideo);
  