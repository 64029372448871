import React, { useState } from 'react';
import {
  withStyles,
  Box,
  InputBase,
  InputBaseHidden,
  Drawer
} from '@material-ui/core';
import NavBar from './NavBar';
import ChatHeader from '../../components/ChatHeader';
import ChatInputText from '../../components/ChatInputText';
import ChatListFactory from './ChatListFactory';
import ProfileView from '../../components/ProfileView';
import { connect } from 'react-redux';
import ChatBox from 'src/components/ChatBox/ChatBox';
const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  mainWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    'max-width': 'fit-content',
    overflow: 'hidden',
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 98
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: '304px'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
});

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMobileNavOpen: false, selectedpage: 'Chat' };
    this.handleClick = this.handleClick.bind(this);
   }

  setMobileNavOpen(value) {
    this.setState({ isMobileNavOpen: value });
  }

  handleClick(pagename) {
    this.setState({ selectedpage: pagename });
  }

  enterNewMessge =(message)=>{
      this.refs.chatbox.addChatMessage(message)
  }

  render() {
    const { classes } = this.props;
    let page;
     if (this.props.selectedMenu == 'profile') {
      page = (
        <div className={classes.mainWrapper}>
          <ProfileView></ProfileView>
        </div>
      );
    } 
    else if(this.props.selectedMenu == 'chatlist'){
      page = (
        <div className={classes.mainWrapper}>
          <ChatHeader></ChatHeader>
          <ChatBox ref="chatbox"></ChatBox>
          <ChatInputText onEnterMessage={this.enterNewMessge}></ChatInputText>
        </div>
      );
    }
    else if(this.props.selectedMenu == 'newchat'){
      page = (
        <div className={classes.mainWrapper}>
          <ChatInputText onEnterMessage={this.enterNewMessge}></ChatInputText>
        </div>
      );
    }else{
      page = (
        <div className={classes.mainWrapper}>
          <ChatHeader></ChatHeader>
          <ChatBox ref="chatbox"></ChatBox>
          <ChatInputText onEnterMessage={this.enterNewMessge}></ChatInputText>
        </div>
      );
    }
     
 

    return (
      <div className={classes.root}>
        <NavBar
          onMobileClose={this.setMobileNavOpen}
          openMobile={this.state.isMobileNavOpen}
          onClickItem={this.handleClick}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <ChatListFactory
                onMobileClose={this.setMobileNavOpen}
                openMobile={this.state.isMobileNavOpen}
                selectedpage={this.state.selectedpage}
              />
            </div>
          </div>
        </div>
        {page}
      </div>
    );
  }
}
var mapStateToProps = state => {
  return {
    selectedMenu: state.selectedMenu
  };
};

export default connect(mapStateToProps)(withStyles(useStyles)(DashboardLayout));

//export default withStyles(useStyles)(DashboardLayout);
