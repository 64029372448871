import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import ChatLayout from 'src/layouts/ChatLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import RoleListView from 'src/views/role/RoleListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import VideoCall from 'src/layouts/VideoCall';


const routes = [
  {
    path: 'admin',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'users', element: <CustomerListView /> },
      { path: 'roles', element: <RoleListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'groups', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'videocall',
    element: <VideoCall />,
    children: [
    ]
  },
  {
    path: 'chat',
    element: <ChatLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'users', element: <CustomerListView /> },
      { path: 'roles', element: <RoleListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'groups', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/admin/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
