import React from 'react';
import {
  withStyles,
  Box,
  InputBase,
  IconButton,
  Divider,
  Paper
} from '@material-ui/core';
import ChatList from '../../views/List/Chat';
import UserList from '../../views/List/Users';
import TopBar from './TopBar';
import AddGroup from 'src/components/AddGroup';
import Search from 'src/components/Search';
import AddFriends from 'src/components/AddFriends';
import GroupTabs from 'src/components/GroupTabs';
import ChatTab from 'src/components/ChatTab';
const useStyles = theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
});

//Js
class ChatListFactory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onMobileClose = props.onMobileClose;
    this.openMobile = props.openMobile;
  }

  render() {
    const { classes } = this.props;
    if (this.props.selectedpage == 'Chat') {
      return (
        <Box>
          <ChatTab></ChatTab>
        </Box>
      );
    } else if (this.props.selectedpage == 'Users') {
      return (
        <Box>
          <AddFriends></AddFriends>
          <Search></Search>
          <UserList />;
        </Box>
      );
    } else if (this.props.selectedpage == 'Groups') {
      return (
        <Box>
          <AddGroup></AddGroup>
          <GroupTabs></GroupTabs>
        </Box>
      );
    } else {
      return <Box />;
    }
  }
}

export default withStyles(useStyles)(ChatListFactory);
