import React from 'react';
import { withStyles, Box, InputBase } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemAvatar, Avatar } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import CustomMessage from './CustomMessage'


//css
const useStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  listitem: {
    width: 'fit-content'
  },
  message:{
      backgroundColor: 'red'
  }
});
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
//Js
class UserMessage extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
          <Box>
        <ListItem className={classes.listitem}>
          <ListItemAvatar>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
              to="/app/account"
            />
          </ListItemAvatar>
          <ListItemText primary="Photos" secondary="Jan 9, 2014" />
        </ListItem>
        <CustomMessage className={classes.message}></CustomMessage>
        </Box>
      </div>
    );
  }
}

export default withStyles(useStyles)(UserMessage);
