import {
  PROFILE_VIEW,
  CHAT_VIEW,
  NEWCHAT_VIEW,
  ACTION_MSG,CLOSE_VIDEO_CALL
} from './ProfileType';

const displayProfile = () => {
  return {
    type: PROFILE_VIEW,
    info: 'Show profile View'
  };
};

const displayChat = () => {
  return {
    type: CHAT_VIEW,
    info: 'Show Chat View'
  };
};
const displayNewChat = () => {
  return {
    type: NEWCHAT_VIEW,
    info: 'Show Chat View'
  };
};
const enterChatMsg = () => {
  return {
    type: ACTION_MSG,
    info: 'Show Chat View'
  };
};

const closevideocall=()=>{
  return{
    type:CLOSE_VIDEO_CALL
  }
}

export { displayProfile, displayChat, displayNewChat, enterChatMsg,closevideocall};
