import React from 'react';
import {
  withStyles,
  Box,
  Avatar,
  IconButton,
  Dialog,
  Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { spacing } from '@material-ui/system';
import { green,red } from '@material-ui/core/colors';
//css
const useStyles = theme => ({
  root: {},
  avatarWrapper: {
      width:'100%',
      display:'flex',
      justifyContent:"center",
   },
  menuWrapper:{
    display:'flex',
    justifyContent:"center",
    margin:'20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 100,
    height: 100,
    margin:'20px'
  },
});
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
//Js
class CallModal extends React.Component {
    constructor(props){
        super(props)
        this.props = props;
        this.handleClose = props.onCloseAudio;
    }

    onClose = (e) =>{
      this.handleClose();
    }
    ClickOnCall=()=>{
      this.handleClose();

    }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Dialog onClose={this.onClose} maxWidth='xs' fullWidth='false' open={this.props.isOpenModal}>
          <Box className={classes.avatarWrapper}>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
              to="/app/account" 
              variant="circular"
            />
          </Box>
          <Typography m={2} variant="h3" align="center">Connecting.....</Typography>
          <Box className={classes.menuWrapper}>
          <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={this.ClickOnCall}
            >
              <CancelIcon style={{ fontSize: 60,color: red[500] }}  />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={this.ClickOnCall}
            >
              <CallOutlinedIcon  style={{ fontSize: 60,color: green[500] }} />
            </IconButton>

          </Box>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(CallModal);
