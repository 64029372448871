import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { withStyles } from '@material-ui/core';

const useStyles = theme => ({
  root: {}
});

//Js
class ComponentName extends React.Component {
  constructor(props) {
    super(props);
    this.state = { on: this.props.status };
  }
  changeIcon =() =>{
    this.setState({ on: !this.state.on }) 
    this.props.onToggle(!this.state.on);
  }
  
  render() {
    const { classes } = this.props;
    let icon = (this.state.on) ? this.props.onIcon:this.props.offIcon;

    return (

      <div className={classes.root}>
        <IconButton onClick={this.changeIcon}>  
          {icon}
        </IconButton>
      </div>
    );
  }
}

var mapStateToProps = state => {
  return {
    selectedMenu: state.selectedMenu
  };
};

//export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
export default withStyles(useStyles)(ComponentName);
