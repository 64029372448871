import React from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemSecondaryAction,
  withStyles,
  Tabs,
  Tab
} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  displayProfile,
  displayChat,
  displayNewChat
} from '../Action/ProfileAction';
import { connect } from 'react-redux';
//css
const styles = theme => ({
  root: {}
});

const ITEM_HEIGHT = 48;

const options = ['New Chat', 'Profile', 'Block', 'Delete'];
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
class ChatListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null, selectedIndex: 0, c: 5 };
  }
  setvalue = newValue => {
    this.setState({ c: newValue });
  };
  handleChange = (event, newValue) => {
    this.setvalue(newValue);
  };
  setSelectedIndex = value => {
    this.setState({ selectedIndex: value });
  };

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };
  setAnchorEl = target => {
    this.setState({ anchorEl: target });
  };
  handleClose = () => {
    this.setAnchorEl(null);
  };

  clickOnProfile = (event, index) => {
    if (index == 0) {
      this.setSelectedIndex(index);
      this.props.OnClickOnNewChat();
    }
    if (index == 1) {
      this.setSelectedIndex(index);
      this.props.onClickProfile();
    }

    this.setAnchorEl(null);
  };
  clickOnItem = () => {
    this.props.clickOnItem();
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ListItem alignItems="flex-start" onClick={this.clickOnItem}>
          <ListItemAvatar>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
              to="/app/account"
            />
          </ListItemAvatar>
          <ListItemText
            primary="Oui Oui"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  Sandra Adams
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch'
                }
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === 'selectedindex'}
                  // onClick={ this.clickOnProfile}
                  onClick={event => this.clickOnProfile(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>

        <Divider component="li" />
      </div>
    );
  }
}

var mapDispatchToProps = dispatch => {
  return {
    onClickProfile: () => dispatch(displayProfile()),
    clickOnItem: () => dispatch(displayChat()),
    OnClickOnNewChat: () => dispatch(displayNewChat())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ChatListItem));
