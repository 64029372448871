import React from 'react';
import {
  withStyles,
  Box,
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Hidden,
  Dialog,
  Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import VideoModal from './VideoModal';
import AddGroupModal from './AddGroupModal';
import Tooltip from '@material-ui/core/Tooltip';

//css
const useStyles = theme => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    left: '0px',
    top: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '13px 31px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%'
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
const ITEM_HEIGHT = 48;

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel'
];

class AddGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isOpenModal: false,
      isOpenVideoModal: false
    };
    this.onMobileNavOpen = props.onMobileNavOpen;
  }

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  };
  setAnchorEl = target => {
    this.setState({ anchorEl: target });
  };
  handleClose = () => {
    this.setAnchorEl(null);
  };
  clickOnCall = e => {
    this.setState({ isOpenModal: true });
  };
  onCloseAudio = e => {
    this.setState({ isOpenModal: false });
  };
  clickOnVideoCall = e => {
    e.stopPropagation();
    this.setState({ isOpenVideoModal: true });
  };
  onCloseVideo = e => {
    this.setState({ isOpenVideoModal: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <AddGroupModal
          isOpenModal={this.state.isOpenVideoModal}
          onCloseVideo={this.onCloseVideo}
        ></AddGroupModal>
        <Grid container>
          <Grid item xs={6} className={classes.name}>
            <Typography>Add Groups</Typography>
          </Grid>
          <Grid item xs={6} display="flex">
            <Box className={classes.menuWrapper}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={this.clickOnVideoCall}
              >
                <Tooltip title="AddGroup">
                  <Add />
                </Tooltip>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(useStyles)(AddGroup);
