import React from 'react';
import {
  withStyles,
  Box,
  TextField,
  Button,
  Checkbox,
  Dialog,
  Typography,
  Avatar
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Ramesh', year: 1994 },
  { title: 'Godfather', year: 1972 },
  { title: 'Suresh', year: 1974 },
  { title: 'Kuldeep', year: 2008 },
  { title: 'Minni', year: 1957 },
  { title: 'Jassie', year: 1993 },
  { title: 'Sam', year: 1994 },
  { title: 'Yara', year: 2003 },
  { title: 'John', year: 1966 },
  { title: 'Sarah', year: 1999 },
  { title: 'Maryam', year: 2001 },
  { title: 'Dilip', year: 1980 },
  { title: 'Naresh', year: 1994 },
  { title: 'Kaalu', year: 2010 },
  { title: 'Dipu', year: 2002 },
  { title: 'Neetu', year: 1975 },
  { title: 'Aishwarya', year: 1990 },
  { title: 'Salman', year: 1999 },
  { title: 'rahul', year: 1954 },
  { title: 'Shahrukh', year: 1977 },
  { title: 'Khan Sahav', year: 2002 }
];
const useStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    topPaddding: '10px',
    flexDirection: 'column',
    padding: '30px'
  },
  Button: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 100,
    height: 100,
    margin: '20px'
  }
});
const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
//Js
class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.handleClose = props.onCloseVideo;
  }

  onClose = e => {
    this.handleClose();
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          onClose={this.onClose}
          maxWidth="xs"
          fullWidth="false"
          open={this.props.isOpenModal}
        >
          <Box className={classes.root}>
            <Typography m={2} variant="h3" align="center">
              Enter Group Name
            </Typography>
            <TextField
              id="standard-basic"
              size="small"
              style={{ padding: '20px 0px' }}
            />
            <AvatarGroup max={4} style={{ padding: '20px 0px' }}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatars/avatar_1.png"
              />
              <Avatar
                alt="Travis Howard"
                src="/static/images/avatars/avatar_2.png"
              />
              <Avatar
                alt="Cindy Baker"
                src="/static/images/avatars/avatar_3.png"
              />
              <Avatar
                alt="Agnes Walker"
                src="/static/images/avatars/avatar_4.png"
              />
              <Avatar
                alt="Trevor Henderson"
                src="/static/images/avatars/avatar_5.png"
              />
            </AvatarGroup>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={top100Films}
              disableCloseOnSelect
              getOptionLabel={option => option.title}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.title}
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Users"
                  placeholder="Favorites"
                />
              )}
            />
          </Box>
          <Box className={classes.Button}>
            <Button
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={this.ClickOnCall}
            >
              Add
            </Button>
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(VideoModal);
