import React from 'react';
import {
  withStyles,
  Box,
  Avatar,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Hidden,
  Dialog
} from '@material-ui/core';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CallModal from './CallModal';
import VideoModal from './VideoModal';
import { values } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';

//css
const useStyles = theme => ({
  chatHeader: {
    width: '100%',
    height: '5rem',
    backgroundColor: 'white',
    left: '0px',
    top: '0px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%'
  },
  profileWrapper: {
    display: 'flex'
  }
});

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
const ITEM_HEIGHT = 48;

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel'
];

class ChatHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null, isOpenVideoModal:false,clicked:"" };
    this.onMobileNavOpen = props.onMobileNavOpen;
   }

  handleClick = event => {
    this.setAnchorEl(event.currentTarget);
  }
  setAnchorEl = target => {
    this.setState({ anchorEl: target });
  }
  handleClose = () => {
    this.setAnchorEl(null);
  }
  clickOnCall = e => {
    this.setState({ isOpenVideoModal: true,clicked:"call" });
   }
 
  clickOnVideoCall = e => {
    e.stopPropagation();
    this.setState({ isOpenVideoModal: true,clicked:"video" });
   };
  onCloseVideo = e =>{
    this.setState({ isOpenVideoModal: false });
   }
 
  render() {
    const { classes,isOpenVideoModal } = this.props;
    return (
      <Box className={classes.chatHeader}>
        <VideoModal isOpenModal={this.state.isOpenVideoModal} clicked={this.state.clicked} onCloseVideo={this.onCloseVideo}></VideoModal>
  
        <Grid container spacing={3}>
          <Grid item xs={6} className={classes.profileWrapper}>
            <Hidden lgUp>
              <IconButton color="inherit" onClick={this.onMobileNavOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  src={user.avatar}
                  to="/app/account"
                />
              </ListItemAvatar>
              <ListItemText primary="Photos" secondary="Jan 9, 2014" />
            </ListItem>
          </Grid>
          <Grid item xs={6} display="flex">
            <Box className={classes.menuWrapper}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={this.clickOnCall}
              >
                 <Tooltip title="AudioCall">
                 <CallOutlinedIcon />
                </Tooltip>
               
              </IconButton>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={this.clickOnVideoCall}
              >
                 <Tooltip title="VideoCall">
                 <VideocamOutlinedIcon />
                </Tooltip>
               
              </IconButton>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch'
                  }
                }}
              >
                {options.map(option => (
                  <MenuItem
                    key={option}
                    selected={option === 'Pyxis'}
                    onClick={this.handleClose}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
var mapStateToProps = state => {
  return {
    isOpenVideoModal: state.isOpenVideoModal
  };
};


 
export default connect(mapStateToProps)(withStyles(useStyles)(ChatHeader));
