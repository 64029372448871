import React from 'react'
import { withStyles,IconButton} from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { green,red } from '@material-ui/core/colors';
import ToggleIcon from '../../components/ToggleIcon'
import {closevideocall} from '../../Action/ProfileAction';
import { connect } from 'react-redux';

const useStyles = (theme) => ({
    root:{
        display:'flex',
        background:' #f6f5f552'

    } 
})
 
//Js
class VideoButtons extends React.Component{
    constructor(props)
    {super(props)
    }

    onAudioToggle = (state) =>{
      this.props.toggleAudio(state);
     }
    onVideoToggle = (state) =>{
      this.props.toggleVideo(state);
    }
    OnCancel=()=>{
      this.props.closeVideo(); 
    }
    render(){
        const {classes} = this.props

        return (<div className={classes.root}>
            <ToggleIcon onToggle = {this.onAudioToggle} status={this.props.audio} onIcon = {<MicIcon/>} offIcon = {<MicOffIcon/>} ></ToggleIcon>

            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={this.OnCancel}
            >
              <CallOutlinedIcon  style={{color: 'red' }} />
            </IconButton>

            <ToggleIcon onToggle = {this.onVideoToggle} status={this.props.video}  onIcon = {<VideocamIcon/>} offIcon = {<VideocamOffIcon/>} ></ToggleIcon>

 
        </div>) 
    }
}
// var mapDispatchToProps = dispatch => {
//   return {
//     closeVideo: () => dispatch(closevideocall()),
//     };
// };

 
 
//export default //connect(null, mapDispatchToProps)(withStyles(useStyles)(VideoButtons));
export default withStyles(useStyles)(VideoButtons);
  