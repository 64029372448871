import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ChatListItem from '../../../components/ChatListItem'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const UserList = () => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
    <ChatListItem></ChatListItem>
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    <ChatListItem></ChatListItem> 
    </List>
  );
}

export default UserList;