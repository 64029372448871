import React from 'react';
import { withStyles } from '@material-ui/core';
//css
const useStyles = theme => ({
  root: {
    width: '180px',
    height: '84px'
  },
  localVideo: {
    width: '100%'
  }
});

const qvgaConstraints = {
  video: { width: { exact: 320 }, height: { exact: 240 } }
};

const vgaConstraints = {
  video: { width: { exact: 640 }, height: { exact: 480 } }
};

const hdConstraints = {
  video: { width: { exact: 1280 }, height: { exact: 720 } }
};

const fullHdConstraints = {
  video: { width: { exact: 1920 }, height: { exact: 1080 } }
};

const televisionFourKConstraints = {
  video: { width: { exact: 3840 }, height: { exact: 2160 } }
};

const cinemaFourKConstraints = {
  video: { width: { exact: 4096 }, height: { exact: 2160 } }
};

const eightKConstraints = {
  video: { width: { exact: 7680 }, height: { exact: 4320 } }
};

//Js
class Localvideo extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate=()=> {
    let localVideo = this.refs.localVideo;

    const streamConstraints = {
      video: { width: this.props.width, height: this.props.height, facingMode: 'user' },
      audio: true
      //aspectRatio: 1
    };
    navigator.mediaDevices
      .getUserMedia(streamConstraints)
      .then(stream => {
        if (this.props.status) {
          localVideo.srcObject = stream;
          if (stream.getAudioTracks().length > 0) {
            // btnMute.style = "display: block";
          }
          this.props.returnStream(stream);
          // isCaller = true;
        }
      })
      .catch(err => {
        console.log('An error ocurred when accessing media devices');
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <video
          ref="localVideo"
          id="localVideo"
          className={classes.localVideo}
          autoPlay
        ></video>
      </div>
    );
  }
}

var mapStateToProps = state => {
  return {
    selectedMenu: state.selectedMenu
  };
};

//export default connect(mapStateToProps)(withStyles(useStyles)(ComponentName));
export default withStyles(useStyles)(Localvideo);
